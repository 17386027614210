<template>
  <div class="container">
    <h4>ตรวจผลรางวัล</h4>
    <DateFilter @submit="search" class="mb-3" />
    <div class="position-relative">
      <div v-for="group in groups" :key="group._id">
        <h5>{{group.groupTitle}}</h5>
        <table class="table table-sm table-bordered table-hover table-info">
          <thead>
            <tr>
              <th>ประเภทหวย</th>
              <th width="20%">งวด</th>
              <th v-if="group.groupType === 'single'" width="15%">3 ตัวบน</th>
              <th v-if="group.groupType === 'single'" width="15%">2 ตัวบน</th>
              <th v-if="group.groupType === 'single'" width="15%">2 ตัวล่าง</th>
              <th v-if="group.groupType === 'lottoset'">ผลหวยชุด</th>
              <th width="140">ตรวจผล</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="round in group.rounds">
              <td>{{ round.note.marketTitle }}</td>
              <td class="text-center">{{ round.roundDate.date | dispDateTime("DD-MM-YYYY") }}</td>
              <td v-if="group.groupType === 'single'" class="text-center">{{ round.results.threeNumberTop[0] }}</td>
              <td v-if="group.groupType === 'single'" class="text-center">{{ round.results.twoNumberTop[0] }}</td>
              <td v-if="group.groupType === 'single'" class="text-center">{{ round.results.twoNumberBottom[0] }}</td>
              <td v-if="group.groupType === 'lottoset'" class="text-center">{{ round.results.fourNumber[0] }}</td>
              <td class="text-center">
                <button class="btn btn-link btn-sm" @click="checkAward(round._id)"><i class="fas fa-search-plus"></i> ตรวจรางวัล</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!groups.length && !isLoading" class="alert alert-warning text-center border-warning">ไม่มีข้อมูล</div>

      <ViewRoundResultModal :is-show="isShowResultModal" :round-id="viewRoundId" @close="isShowResultModal=false" />

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </div>
</template>
<script>
import DateFilter from '@/views/report/components/DateFilter'
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import ViewRoundResultModal from './result/components/ViewRoundResultModal'
import cAlert from '@/helpers/alert'

export default {
  name: 'Results',
  components: {
    DateFilter,
    ViewRoundResultModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      filters: {
        startDate: null,
        endDate: null
      },
      isShowResultModal: false,
      viewRoundId: null
    }
  },
  computed: {
    groups() {
      if(!this.data)
        return []

      return _.orderBy(this.data.groups, ['sort'], ['asc'])
      .map((group)=>{
        const rounds = this.data.items.filter((round)=>{
          return group._id === round.groupId
        })
        group.rounds = _.orderBy(rounds, (round)=>{
          return round.roundDate.close
        }, ['asc'])
        return group
      })
      .filter((group)=>{
        return group.rounds.length > 0
      })
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        ...filters,
        startDate: filters.date.start,
        endDate: filters.date.end,
      }
      this.loadResults()
    },
    loadResults() {
      this.isLoading = true
      ReportService.getResults(this.filters).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดผลรางวัลไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    checkAward(roundId) {
      this.viewRoundId = roundId
      this.isShowResultModal = true
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin-bottom: 50px;

  .table {
    thead {
      tr {
        th {
          // font-weight: normal;
          padding: 2px;
          font-size: 90%;
          text-align: center;
        }
      }
    }
    tbody {
      background-color: #FFF;
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
